<template>
  <button class="s-text-caption btn-add">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5V19M5 12H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    </svg>
    <span> <slot></slot> </span>
  </button>
</template>

<style lang="scss" scoped>
button {
  cursor: pointer;
  padding: 0 13px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  outline: none;
  color: var(--dark-100);
  background: var(--blue-light);
  text-transform: uppercase;
  flex-shrink: 0;
  transition: 0.2s ease-out;

  @media (max-width: 359px) {
    padding: 0 5px;
    font-size: 10px;
  }
  svg {
    height: 17px;
    width: 17px;
    color: var(--blue-main);
    margin-bottom: 0;
    margin-right: 3px;
    transition: 0.2s ease-out;
  }
  &:hover {
    background: var(--blue-main);
    color: var(--white-100);
    svg {
      color: var(--white-100);
    }
  }
}
</style>
