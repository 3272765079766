<template>
  <div class="dish-day">
    <div class="header">
      <div class="day s-text-caption">{{ day }}</div>
      <div v-if="totalPrice" class="price s-text-caption">{{ totalPrice }} ₴</div>
      <div v-else class="add-dish s-text-caption">Додайте страву</div>
    </div>
    <div class="dish-status">
      <div v-if="confirm && !timeEnd" class="already-preparing">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.33594 8.33333L7.66927 11.6667L14.3359 5" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.66667 8.33333L11 5M1 8.33333L4.33333 11.6667L1 8.33333Z" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="s-text-caption">{{ confirm }}</span>
      </div>
      <div v-else-if="confirm" class="order-confirmation">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 5V8.125L10 10" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="s-text-caption">{{ timeEnd }} {{ confirm }}</span>
      </div>
    </div>
    <div v-if="dishes">
      <transition-group name="fade" @before-enter="beforeEnter" @before-leave="leave">
        <div class="dish-cell" style="min-height: 56px; height: 100%;" v-for="item in dishes" :key="item.id">
          <img :src="item?.product?.image?.url" :alt="item?.product?.image?.title" />
          <div class="cell-text m-text-des">
            <span :title="item?.product?.name" class="cell-title">{{ item?.product?.name }} </span>
            
            <template v-if="item?.product?.modifiers?.applied?.add.length">
              <div class="modifier-list" style="padding-top: 5px">
                <div v-for="item in appliedModifiers(item?.product?.modifiers)" :key="item?.id" class="modifier s-text-caption count-price">{{ item?.name }} {{ item?.price ? `+${item?.price}₴` : '' }}</div>
              </div>
            </template>
            
            <div class="count-price s-text-caption">
              {{ item?.qty }} х {{ item?.product?.price }} ₴
              <div v-if="!confirm && timeEnd == '' || timeEnd != '' && confirm" @click="removeDishFromDay(item)" class="remove-dish-btn">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.30078 12.5212L12.0754 4.74658M4.30078 4.74658L12.0754 12.5212" stroke="#000E29" stroke-opacity="0.4" stroke-width="1.45775" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <span v-if="item?.surcharge" class="surcharge">Доплата {{ item?.surcharge }} ₴</span>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalPrice: {
      type: [Number, String]
    },
    confirm: {
      type: String
    },
    timeEnd: {
      type: String
    },
    day: {
      type: String,
      default: "Понедельник"
    },
    dishes: {
      type: Object
    }
  },

  methods: {
    removeDishFromDay(item) {
      this.$emit("removeDishFromDay", item.id);
      this.$emit("removeDishClick");
    },
    beforeEnter: function() {
      let heightCard = this.$el.clientHeight;
      let animate = this.$el.animate([{ height: heightCard + "px" }, { height: heightCard + 68 + "px" }], 150);
      animate.onfinish = () => {
        this.$el.style.height = heightCard + 68 + "px";
      };
    },
    leave: function() {
      let heightCard = this.$el.clientHeight;
      this.$el.style.height = heightCard + 68 + "px";
      let animate = this.$el.animate([{ height: heightCard + "px" }, { height: heightCard - 68 + "px" }], { duration: 250, easing: "ease-out" });
      animate.onfinish = () => {
        this.$el.style.height = heightCard - 68 + "px";
      };
    },
    appliedModifiers(modifier) {
      const applied = modifier?.applied?.add;
      const modifiers = modifier?.default?.modifiers;
      let newArr = [];
      applied.forEach(apply => {
        const res = modifiers.filter(item => item?.id == apply);
        if (res.length) {
          newArr.push(res[0]);
        }
      });
      return newArr;
    },
  }
};
</script>

<style lang="scss" scoped>
.dish-day {
  min-height: 0px;
  background: var(--white-100);
  padding: 20px;
  border-radius: 10px;
  width: 355px;
  margin: 2px 0;
}
.header {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.add-dish,
.price,
.day {
  text-transform: uppercase;
}
.add-dish {
  cursor: pointer;
  color: var(--dark-40);
}
.order-confirmation,
.already-preparing {
  cursor: default;
  display: flex;
  align-items: center;
  color: var(--blue-main);
  svg {
    margin-top: -2px;
    margin-right: 4px;
  }
}
.dish-cell {
  transition: all 0.3s;
  margin-top: 12px;
  display: flex;
  height: 56px;
  img {
    height: 100%;
    max-width: 56px;
  }
}
.cell-title {
  margin-bottom: -9px;
  max-width: 247px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cell-text {
  width: calc(100% - 56px);
  margin-left: 12px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}
.count-price {
  display: flex;
  align-items: center;
  color: var(--dark-40);
  .remove-dish-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 2px;
  }
}
.surcharge {
  user-select: none;
  color: var(--blue-main);
  margin-left: auto;
}
.fade-enter-active {
  animation: bounce-in 0.5s;
}
.fade-leave-active {
  position: absolute;
}
.fade-leave-to {
  transform: scale(0);
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
</style>
