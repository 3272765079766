<template>
  <div class="employees-page">
    <DarkBg class="dark-bg-component"></DarkBg>
    <Dialog class="dish-remove-dialog" @cancelDialog="cancelDishRemoveDialog" @submitDialog="dishRemove" v-if="dialogDishDeleteConfirm" v-model:show="dialogDishDeleteConfirm" :firstBtnText="EMPLOYEES_TRANSLATE?.list?.modal?.btn" :secondBtnText="EMPLOYEES_TRANSLATE?.list?.modal?.dish_cancel">
      <template v-slot:title>
        {{ EMPLOYEES_TRANSLATE?.list?.modal?.title?.dish?.one?.text_one }}
        <span class="blue">{{ dialogRemoveDish.name }} {{ dialogRemoveDish.last_name }}</span>
        {{ EMPLOYEES_TRANSLATE?.list?.modal?.title?.dish?.one?.text_two }}
        <span class="grey-text">{{ dialogRemoveDish.day }}</span>
        ?
      </template>
      <template v-slot:checkbox-confirm>
        <div class="confirm-box">
          <label class="check-confirm">
            <input type="checkbox" @click="dontAskFlag = !dontAskFlag" />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.0643 18L20.4016 6M8.97718 17.9996L3.60156 12.2635" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </label>
          <span class="grey-text s-text-alert">{{ EMPLOYEES_TRANSLATE?.list?.modal?.title?.dish?.ask_today }}</span>
        </div>
      </template>
    </Dialog>
    <Dialog class="dish-remove-dialog" @cancelDialog="cancelDishDayRemoveDialog" @submitDialog="dishDayRemove" v-if="dialogDishDayDeleteConfirm" v-model:show="dialogDishDayDeleteConfirm" :firstBtnText="EMPLOYEES_TRANSLATE?.list?.modal?.dish_btn" :secondBtnText="EMPLOYEES_TRANSLATE?.list?.modal?.dish_cancel">
      <template v-slot:title>
        {{ EMPLOYEES_TRANSLATE?.list?.modal?.title?.dish?.many?.text_one }}
        <span class="blue">{{ dialogRemoveDishDay.name }} {{ dialogRemoveDishDay.last_name }}</span>
        {{ EMPLOYEES_TRANSLATE?.list?.modal?.title?.dish?.many?.text_two }}
        <span class="grey-text">{{ dialogRemoveDishDay.day }}</span
        >?
      </template>
      <template v-slot:checkbox-confirm>
        <div class="confirm-box">
          <label class="check-confirm">
            <input type="checkbox" @click="dontAskDayFlag = !dontAskDayFlag" />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.0643 18L20.4016 6M8.97718 17.9996L3.60156 12.2635" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </label>
          <span class="grey-text s-text-alert">{{ EMPLOYEES_TRANSLATE?.list?.modal?.title?.dish?.ask_today }}</span>
        </div>
      </template>
    </Dialog>
    <Dialog class="dialog-edit-company" @cancelDialog="cancelAddEmployeeDialog" @submitDialog="submitAddEmployee" v-show="dialogAddEmployee" v-model:show="dialogAddEmployee" :firstBtnText="EMPLOYEES_TRANSLATE?.list?.form?.btn?.add" :secondBtnText="EMPLOYEES_TRANSLATE?.list?.form?.btn?.cancel">
      <template v-slot:title>{{ EMPLOYEES_TRANSLATE?.list?.form?.title?.add }}</template>
      <span class="message m-text-des-n" v-if="EMPLOYEES_TRANSLATE?.list?.form?.text?.add">{{ EMPLOYEES_TRANSLATE.list.form.text.add }}</span>
      <div class="inputs-box">
        <InputText v-model:value.trim="dialogEditEmployee.name" :errorText="VALIDATION_TRANSLATE?.employees?.name" :valid="validName" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.name"></InputText>
        <InputText v-model:value.trim="dialogEditEmployee.last_name" :errorText="VALIDATION_TRANSLATE?.employees?.last_name" :valid="validLast_name" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.last_name"></InputText>
        <InputText v-model:value.trim="dialogEditEmployee.email" :errorText="VALIDATION_TRANSLATE?.employees?.email" :valid="validEmail" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.email"></InputText>
        <InputText v-model:value.trim="dialogEditEmployee.phone" :errorText="VALIDATION_TRANSLATE?.employees?.phone" :valid="validPhone" class="telephone" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.phone"></InputText>
        <InputSelect v-if="GET_ROLES" :options="GET_ROLES" @selectPressed="selectRole" :key="selectUpdated" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.role"></InputSelect>
        <InputSelect :options="limitList" selectType="limit" @selectPressed="selectLimit" :key="selectUpdated1" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.limit"></InputSelect>
        <InputText v-model:value.trim="dialogEditEmployee.birthday" :errorText="VALIDATION_TRANSLATE?.employees?.birthday" :valid="birthday.valid" class="birthday" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.birthday">
          <InfoIcon v-if="EMPLOYEES_TRANSLATE?.list?.form?.fields?.birthday_tip" bottom="28px" left="-24px" arrowDir="bl">
            {{ EMPLOYEES_TRANSLATE?.list?.form?.fields?.birthday_tip }}
          </InfoIcon>
        </InputText>
        <InputText v-model:value.trim="dialogEditEmployee.min_order" :errorText="VALIDATION_TRANSLATE?.employees?.min_order" :valid="validMinOrder" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.min_order"></InputText>
      </div>
    </Dialog>
    <Dialog class="dialog-edit-company" @cancelDialog="cancelEditEmployeeDialog" @submitDialog="submitEditEmployee" v-show="dialogEditEmployee.show" v-model:show="dialogEditEmployee.show" :firstBtnText="EMPLOYEES_TRANSLATE?.list?.form?.btn?.add" :secondBtnText="EMPLOYEES_TRANSLATE?.list?.form?.btn?.cancel">
      <template v-slot:title>{{ EMPLOYEES_TRANSLATE?.list?.form?.title?.edit }}</template>
      <span class="message m-text-des-n" v-if="EMPLOYEES_TRANSLATE?.list?.form?.text?.edit">{{ EMPLOYEES_TRANSLATE.list.form.text.edit }}</span>
      <div class="inputs-box">
        <InputText v-model:value.trim="dialogEditEmployee.name" :errorText="VALIDATION_TRANSLATE?.employees?.name" :valid="validName" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.name"></InputText>
        <InputText v-model:value.trim="dialogEditEmployee.last_name" :errorText="VALIDATION_TRANSLATE?.employees?.last_name" :valid="validLast_name" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.last_name"></InputText>
        <InputText v-if="!guestId" v-model:value.trim="dialogEditEmployee.email" :errorText="VALIDATION_TRANSLATE?.employees?.email" :valid="validEmail" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.email"></InputText>
        <InputText v-if="!guestId" v-model:value.trim="dialogEditEmployee.phone" :errorText="VALIDATION_TRANSLATE?.employees?.phone" :valid="validPhone" class="telephone" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.phone"></InputText>
        <InputSelect v-if="!guestId && GET_ROLES && GET_ME != dialogEditEmployee.id" :key="selectUpdated2" :options="GET_ROLES" :default="dialogEditEmployee.role" :activeOption="dialogEditEmployee.role_default" @selectPressed="selectRole" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.role"></InputSelect>
        <InputSelect :options="limitList" selectType="limit" :default="dialogEditEmployee.limit" :key="selectUpdated3" :activeOption="dialogEditEmployee.limit_default" @selectPressed="selectLimit" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.limit"></InputSelect>
        <InputText v-if="!guestId" v-model:value.trim="dialogEditEmployee.birthday" :errorText="VALIDATION_TRANSLATE?.employees?.birthday" :valid="birthday.valid" class="birthday" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.birthday">
          <InfoIcon v-if="EMPLOYEES_TRANSLATE?.list?.form?.fields?.birthday_tip" bottom="28px" left="-24px" arrowDir="bl">
            {{ EMPLOYEES_TRANSLATE?.list?.form?.fields?.birthday_tip }}
          </InfoIcon>
        </InputText>
        <InputText v-model:value.trim="dialogEditEmployee.min_order" :errorText="VALIDATION_TRANSLATE?.employees?.min_order" :valid="validMinOrder" :label="EMPLOYEES_TRANSLATE?.list?.form?.fields?.min_order"></InputText>
      </div>
      <template v-if="GET_ME != dialogEditEmployee.id" v-slot:remove-btn>
        <div class="remove-btn-wrapper">
          <button class="remove-btn" @click="employeeRemove">
            {{ EMPLOYEES_TRANSLATE?.list?.form?.btn?.delete }}
          </button>
        </div>
      </template>
    </Dialog>
    <Dialog v-if="dialogEmployeeDeleteConfirm" v-model:show="dialogEmployeeDeleteConfirm" @cancelDialog="cancelRemoveEmployeeDialog" @submitDialog="submitRemoveEmployee" :firstBtnText="EMPLOYEES_TRANSLATE?.list?.modal?.btn" :secondBtnText="EMPLOYEES_TRANSLATE?.list?.form?.btn?.cancel" firstBtnMode="error">
      <template v-slot:title>
        {{ EMPLOYEES_TRANSLATE?.list?.modal?.title?.part_one }}
        <span class="red">{{ dialogEditEmployee.name }} {{ dialogEditEmployee.last_name }}</span>
        {{ EMPLOYEES_TRANSLATE?.list?.modal?.title?.part_two }}
      </template>
    </Dialog>
    <DialogChooseEmployee v-model:show="CHOOSE_EMPLOYEE"></DialogChooseEmployee>
    <div class="main">
      <div class="employees-header">
        <h2 class="r-title-1">{{ employeesCount }} {{ plural }}</h2>
        <div class="employees-header-buttons">
          <InfoBtn @click="stepsReinit">
            <div class="guest-info">
              <div class="guest-info-inner">
                {{ EMPLOYEES_TRANSLATE?.list?.tip?.work }}
              </div>
            </div>
          </InfoBtn>
          <SearchDish @inpEvent="searchFunc" @searchClear="clearSearchFunc" :placeholder="EMPLOYEES_TRANSLATE?.list?.search" v-model:value="search"></SearchDish>
          <div class="employee-add-wrapper">
            <ButtonAdd class="mb-10" @click="addEmployee">{{ EMPLOYEES_TRANSLATE?.list?.btn?.add }}</ButtonAdd>
            <InfoMessage v-if="dataEmployee" right="33%" top="135%" arrowDir="tr" :class="{ show: infoStep == 1 }">
              <div class="steps-box">
                <span class="step-text m-text-steps-title">{{ EMPLOYEES_TRANSLATE?.list?.tip?.data[0] }}</span>
                <div class="step-interactive-box">
                  <span class="active"></span>
                  <span></span>
                  <span></span>
                  <div @click="nextInfoStep" class="step-btn s-text-alert">
                    {{ EMPLOYEES_TRANSLATE?.list?.tip?.btn }}
                  </div>
                </div>
              </div>
            </InfoMessage>
          </div>
        </div>
      </div>
      <div class="employees-list">
        <div class="employees-list-header" :class="{ sticky: stickyFlag }">
          <div class="employees-list-title title-name">
            <span class="hovered" @click="sortByName">
              {{ EMPLOYEES_TRANSLATE?.list?.name }}
              <svg v-if="sortNameFlag" :class="{ rotated: sortName == -1 }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
          <div class="employees-list-title title-phone">
            <span>{{ EMPLOYEES_TRANSLATE?.list?.phone }}</span>
          </div>
          <div class="employees-list-title title-limit">
            <span class="hovered" @click="sortByLimit">
              {{ EMPLOYEES_TRANSLATE?.list?.limit }}
              <svg v-if="sortLimitFlag" :class="{ rotated: sortLimit == -1 }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
          <div class="employees-list-title title-status">
            <span class="hovered" @click="sortByDays">
              {{ EMPLOYEES_TRANSLATE?.list?.status }}
              <span class="date-interval">с {{ dateStart }} по {{ dateEnd }}</span
              >&nbsp;
              <svg v-if="sortDaysFlag" :class="{ rotated: sortDay == 1 }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        </div>
        <div v-if="employeesCount > 0" class="employees-list-body" :key="employeesBodyKey">
          <template v-if="!emptyTextFlag">
            <div class="employees-list-item" v-for="(employee, index) in dataEmployee" :key="index">
              <div class="employee-name" :class="{ guest: employee?.is_guest == 1 }" @click="editEmployee(employee)">
                <template v-if="employee.is_guest == 1">
                  <svg class="employee-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8 5.33301H8.00667" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.33203 8H7.9987V10.6667H8.66536" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="guest-info">
                    <div class="guest-info-inner">
                      {{ EMPLOYEES_TRANSLATE?.list?.user_quest }}
                    </div>
                  </div>
                </template>
                <svg v-else class="employee-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 12H3.13144L11.3515 3.77998C11.7667 3.36472 12 2.80152 12 2.21426C12 1.627 11.7667 1.0638 11.3515 0.648542C10.9362 0.233288 10.373 4.37542e-09 9.78574 0C9.19848 -4.37542e-09 8.63528 0.233288 8.22002 0.648542L0 8.86856V12Z" fill="#3CAFE9" />
                </svg>
                {{ employee?.name }}
                <template v-if="employee?.is_guest == 0">{{ employee?.last_name }}</template>
                <span v-if="employee?.role?.id == 2" class="rank">({{ employee?.role?.label }})</span>
              </div>
              <div class="employee-phone">
                <a v-if="!employee.is_guest" :href="`tel:${employee?.phone}`">{{ phoneFormat(employee?.phone) }}</a>
              </div>
              <div class="employee-limit">{{ employee?.limit?.sum ?? 0 }} ₴</div>
              <div class="employee-offers">
                <div class="dish-btns">
                  <div class="dish-check-container">
                    <div class="s-text-caption" v-for="(day, index) in employee?.days" :key="index" @mouseenter="dishDayPos" @mouseleave="this.dishdayPosFlag = null">
                      <template v-if="employee?.orders.length > 0 && day?.items.length > 0">
                        <div class="dish-day-wrapper" :class="{ bottom: !dishDayPosFlag }">
                          <EmployeeOrderCard @removeDishFromDay="removeDishFromDay" @removeDishClick="dishRemoveDialog(employee, day)" :dishes="day?.items[0]?.items" :day="day?.name" :totalPrice="day?.items[0]?.price" :timeEnd="timeEnd(day?.items[0])" :confirm="day?.items[0]?.options?.status"></EmployeeOrderCard>
                        </div>
                        <div v-if="day?.items.length > 0 && ((day?.items?.[0]?.options?.timer && day?.items?.[0]?.options?.status != '') || (!day?.items?.[0]?.options?.timer && day?.items?.[0]?.options?.status == ''))" @click="dishDayRemoveDialog(employee, day)">
                          <div class="dish-btn checked" @click="counterRefresh">
                            <svg class="status-1" width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.33594 4.33333L4.66927 7.66667L11.3359 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <svg class="status-2" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.83398 3.33301L13.1673 12.6663M13.1673 3.33301L3.83398 12.6663" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            {{ day?.short_name }}
                          </div>
                        </div>
                        <div v-else>
                          <div class="dish-btn checked" @click="counterRefresh">
                            {{ day?.short_name }}
                          </div>
                        </div>
                      </template>
                      <div v-else>
                        <div class="dish-btn">
                          <svg class="status-1" width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.33594 4.33333L4.66927 7.66667L11.3359 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          {{ day?.short_name }}
                        </div>
                      </div>
                    </div>
                    <InfoMessage v-if="index == 0 && dataEmployee" left="50%" top="135%" arrowDir="tc" :class="{ show: infoStep == 2 }">
                      <div class="steps-box">
                        <span class="step-text m-text-steps-title">{{ EMPLOYEES_TRANSLATE?.list?.tip?.data[1] }}</span>
                        <div class="step-interactive-box">
                          <span></span>
                          <span class="active"></span>
                          <span></span>
                          <div @click="nextInfoStep" class="step-btn s-text-alert">
                            {{ EMPLOYEES_TRANSLATE?.list?.tip?.btn }}
                          </div>
                        </div>
                      </div>
                    </InfoMessage>
                  </div>
                  <slot name="introduction"></slot>
                </div>
              </div>
              <div class="offer-btn-wrapper">
                <button class="offer-btn" @click="routeToDish(employee)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5L5 12M12 5H5M12 5V11.7742" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{ EMPLOYEES_TRANSLATE?.list?.btn?.order }}
                </button>
                <InfoMessage v-if="index == 0 && dataEmployee" right="9px" top="calc(100% + 13px)" arrowDir="tr" :class="{ show: infoStep == 3 }">
                  <div class="steps-box">
                    <span class="step-text m-text-steps-title">{{ EMPLOYEES_TRANSLATE?.list?.tip?.data[2] }}</span>
                    <div class="step-interactive-box">
                      <span></span>
                      <span></span>
                      <span class="active"></span>
                      <div @click="nextInfoStep" class="step-btn s-text-alert">
                        {{ EMPLOYEES_TRANSLATE?.list?.tip?.btn }}
                      </div>
                    </div>
                  </div>
                </InfoMessage>
              </div>
            </div>
          </template>
          <div v-else class="employees-list-item empty-text m-text-des">
            <p>
              {{ EMPLOYEES_TRANSLATE?.list?.search_text?.label }}&nbsp;<span class="gray">{{ EMPLOYEES_TRANSLATE?.list?.search_text?.description }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <a v-if="pageYOffset > topOffset" v-smooth-scroll="{ duration: 700, updateHistory: false }" href="#navEmployees" class="btn-to-top">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 10L12 3M19 10L12 3M12 3L12 21" stroke="#000E29" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>
    <Footer class="footer-wrapper"></Footer>
  </div>
</template>

<script>
import Inputmask from "inputmask";
import DarkBg from "@/components/dark-bg.vue";
import DialogChooseEmployee from "@/components/dialogs/choose-employee.vue";
import SearchDish from "@/components/parts/search-dish";
import ButtonAdd from "@/components/parts/button-add.vue";
import EmployeeOrderCard from "@/components/parts/employee-order-card";
import InfoMessage from "@/components/parts/info-message";
import InfoBtn from "@/components/parts/info-btn";
import InputText from "@/components/parts/input-text.vue";
import InfoIcon from "@/components/parts/info-icon.vue";
import InputSelect from "@/components/parts/input-select";
import Dialog from "@/components/dialogs/dialog-submit-setting.vue";
import Footer from "@/components/footer-setting.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DarkBg,
    DialogChooseEmployee,
    SearchDish,
    ButtonAdd,
    EmployeeOrderCard,
    InfoMessage,
    InfoBtn,
    InputText,
    InfoIcon,
    InputSelect,
    Dialog,
    Footer
  },
  data() {
    return {
      stickyFlag: false,
      clicks: 0,
      dontAskFlag: false,
      dontAskDayFlag: false,
      dishDayPosFlag: null,
      employeesBodyKey: 1,
      dateStart: "",
      dateEnd: "",
      dataEmployee: null,
      emptyTextFlag: false,
      width: 0,
      topOffset: 0,
      pageYOffset: 0,
      infoStep: 0,
      selectUpdated: 1,
      selectUpdated1: 2,
      selectUpdated2: 3,
      selectUpdated3: 4,
      dialogDishDeleteConfirm: false,
      dialogDishDayDeleteConfirm: false,
      dialogEmployeeDeleteConfirm: false,
      dialogAddEmployee: false,
      guestId: null,
      dialogRemoveDish: {
        id: null,
        name: "",
        last_name: "",
        item_id: null,
        order_id: null,
        day: ""
      },
      dialogRemoveDishDay: {
        id: null,
        name: "",
        last_name: "",
        order_id: null,
        day: ""
      },
      dialogEditEmployee: {
        id: null,
        show: false,
        name: "",
        last_name: "",
        email: "",
        phone: "",
        role_id: null,
        role: null,
        role_default: null,
        limit_id: null,
        limit: null,
        limit_default: null,
        birthday: "",
        min_order: null,
      },
      sortNameFlag: true,
      sortLimitFlag: false,
      sortDaysFlag: false,
      sortName: 1,
      sortLimit: 1,
      sortDay: 1,
      search: ""
    };
  },
  computed: {
    ...mapGetters(["ORDERS", "CHOOSE_EMPLOYEE", "VALIDATION_TRANSLATE", "COMPANY", "GET_EMPLOYEES", "GET_DATE", "EMPLOYEES_TRANSLATE", "GET_ME", "GET_ROLES"]),
    employeesCount() {
      return this.GET_EMPLOYEES.length;
    },
    plural() {
      let plur = this.employeesCount % 10 == 1 && this.employeesCount % 100 != 11 ? 0 : this.employeesCount % 10 >= 2 && this.employeesCount % 10 <= 4 && (this.employeesCount % 100 < 10 || this.employeesCount % 100 >= 20) ? 1 : 2;
      return plur == 0 ? this.EMPLOYEES_TRANSLATE?.list?.title?.one : plur == 1 ? this.EMPLOYEES_TRANSLATE?.list?.title?.second : this.EMPLOYEES_TRANSLATE?.list?.title?.third;
    },
    limitList() {
      let newArr = [];
      if (this.COMPANY) {
        let limitsArr = this.COMPANY.limits;
        for (let i = 0; i < limitsArr.length; i++) {
          let customObject = {
            label: limitsArr[i].sum,
            id: limitsArr[i].id,
            number: i
          };
          newArr.push(customObject);
        }
      }
      return newArr;
    },
    validName() {
      return !this.dialogEditEmployee.name.match(/[^А-Яа-яЁёЇїІіЄєҐґa-zA-Z]/);
    },
    validMinOrder() {
      if (null === this.dialogEditEmployee.min_order) {
        return true;
      }

      const isDigit = this.dialogEditEmployee.min_order.toString().match(/^\d+$/);
      if (!isDigit) {
        return false;
      }

      if (this.dialogEditEmployee.min_order <= 0) {
        return false;
      }

      return true;
    },
    validLast_name() {
      return !this.dialogEditEmployee.last_name.match(/[^А-Яа-яЁёЇїІіЄєҐґa-zA-Z]/);
    },
    validPhone() {
      let phone = this.dialogEditEmployee.phone
        .split("")
        .filter(e => +e || e === "0")
        .join("")
        .replace("380", "");
      return !(phone.length < 9);
    },
    birthday() {
      let inputDate = {
        dd: this.dialogEditEmployee.birthday.slice(0, 2),
        mm: this.dialogEditEmployee.birthday.slice(5, 7),
        yyyy: this.dialogEditEmployee.birthday.slice(10, 14)
      };
      let now = new Date();
      let date = new Date(inputDate.yyyy, inputDate.mm - 1, inputDate.dd);
      date.getFullYear() == inputDate.yyyy && date.getMonth() == inputDate.mm - 1 && date.getDate() == inputDate.dd && inputDate.yyyy >= 1950 && date.getTime() < now.getTime() ? (inputDate.valid = true) : (inputDate.valid = false);
      return inputDate;
    },
    validEmail() {
      const pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return pattern.test(this.dialogEditEmployee.email);
    }
  },
  methods: {
    ...mapActions(["GetOrders", "ValidationTranslate", "SelectUser", "DeleteDish", "DeleteDayDishes", "UpdateCompany", "EmployeesList", "EmployeesTranslate", "Roles", "AddEmployee", "EditEmployee", "DeleteEmployee", "RestoreEmployee"]),
    updateWidth() {
      this.width = window.innerWidth;
    },
    nextInfoStep() {
      if (this.infoStep <= 3) this.infoStep++;
      if (this.infoStep == 4) {
        localStorage.setItem("step-employee", true);
        document.querySelector(".dark-bg-component").style.display = "none";
      }
    },
    stepsReinit() {
      this.infoStep = 1;
      localStorage.setItem("step-employee", false);
      document.querySelector(".dark-bg-component").style.display = "block";
    },
    async userModal() {
      document.querySelector("body").style.overflow = "hidden";
      this.$store.commit("Set_Choose_Employee", true);
      await this.EmployeesList();
    },
    routeToDish(user) {
      this.$router.push({ name: "Dishes" });
      this.SelectUser(user);

      this.SelectUser(user);
      this.GetOrders();
      this.GetAllDishes("?isGroup=1");
      this.visib = false;
      document.querySelector("body").style.overflow = null;
      this.$store.commit("Set_Choose_Employee", false);
      this.$store.commit("SetSurchargeChecker", true);
    },
    dishDayPos(event) {
      if (event.target.querySelector(".dish-day-wrapper")) {
        let posTop = event.target.getBoundingClientRect().top;
        let block = event.target.querySelector(".dish-day-wrapper").offsetHeight + 10;
        block < posTop ? (this.dishDayPosFlag = true) : (this.dishDayPosFlag = false);
      }
    },
    timeEnd(data) {
      if (data.options.time_end) {
        let t = data.options.time_end.split(/[- :]/);
        let d = new Date(t[0], t[1] - 1, t[2], t[3], t[4]);
        let end = new Date(d).getTime();
        let now = new Date().getTime();
        let distance = end - now;
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) > 9 ? Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : `0${Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))}`;
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) > 9 ? Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) : `0${Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))}`;
        return `${hours}:${minutes}`;
      } else {
        return "";
      }
    },
    phoneFormat(phone) {
      if (phone) {
        let tel = phone.split("");
        tel.splice(0, 2);
        tel.splice(3, 0, " ");
        tel.splice(7, 0, " ");
        tel.splice(10, 0, " ");
        let newTel = tel.join("");
        return newTel;
      }
    },
    clearSearchFunc() {
      this.search = "";
      this.dataEmployee = this.GET_EMPLOYEES;
      this.sortNameFlag = true;
      this.emptyTextFlag = false;
    },
    searchFunc() {
      if (!this.search) {
        this.dataEmployee = this.GET_EMPLOYEES;
        this.sortNameFlag = true;
        this.emptyTextFlag = false;
      } else {
        this.sortNameFlag = false;
        this.sortLimitFlag = false;
        let subStr = this.search.toLowerCase();
        let newArr = this.GET_EMPLOYEES.filter(item => `${item.name.toLowerCase()} ${item.last_name.toLowerCase()}`.includes(subStr));
        newArr.sort((a, b) => (`${a.name.toLowerCase()}${a.last_name.toLowerCase()}`.indexOf(subStr) > `${b.name.toLowerCase()}${b.last_name.toLowerCase()}`.indexOf(subStr) ? 1 : -1));
        this.dataEmployee = newArr;
        this.dataEmployee.length > 0 ? (this.emptyTextFlag = false) : (this.emptyTextFlag = true);
      }
      this.employeesBodyKey *= -1;
    },
    sortByName() {
      this.sortNameFlag = true;
      this.sortLimitFlag = false;
      this.sortDaysFlag = false;
      this.sortName = this.sortName * -1;

      let temp = this.dataEmployee.sort((a, b) => (a.is_guest > b.is_guest ? 1 : -1)).pop();
      this.dataEmployee.sort((a, b) => (`${a.name}${a.last_name}` > `${b.name}${b.last_name}` ? 1 : -1) * this.sortName);
      this.dataEmployee.push(temp);
      this.employeesBodyKey *= -1;
    },
    sortByDays() {
      this.sortLimitFlag = false;
      this.sortNameFlag = false;
      this.sortDaysFlag = true;
      this.sortDay = this.sortDay * -1;

      let newArr = this.dataEmployee;
      newArr.sort((a, b) => (a.firstDay > b.firstDay ? 1 : -1)).sort((a, b) => (a.count > b.count ? 1 : -1) * this.sortDay);
      this.dataEmployee = newArr;
      this.employeesBodyKey *= -1;
    },
    sortByLimit() {
      this.sortLimitFlag = true;
      this.sortNameFlag = false;
      this.sortDaysFlag = false;
      this.sortLimit = this.sortLimit * -1;
      let final = [];
      let nullLimit = this.dataEmployee.filter(e => !e.limit);
      let fullLimit = this.dataEmployee.filter(e => e.limit).sort((a, b) => (a.limit.sum < b.limit.sum ? 1 : -1) * this.sortLimit);
      this.sortLimit == -1 ? (final = [...nullLimit, ...fullLimit]) : (final = [...fullLimit, ...nullLimit]);
      this.dataEmployee = final;
      this.employeesBodyKey *= -1;
    },
    removeDishFromDay(item) {
      this.dialogRemoveDish.item_id = item;
    },
    async dishRemoveDialog(employee, day) {
      this.dialogDishDayDeleteConfirm = false;
      if (!localStorage.getItem("dontAsk")) {
        document.querySelector("body").style.overflow = "hidden";
        this.dialogDishDeleteConfirm = true;
      }
      this.dialogRemoveDish.id = employee.id;
      this.dialogRemoveDish.name = employee.name;
      this.dialogRemoveDish.last_name = employee.last_name;
      this.dialogRemoveDish.day = day.name;
      this.dialogRemoveDish.order_id = day.items[0].id;
      if (localStorage.getItem("dontAsk")) {
        await this.DeleteDish({
          user: this.dialogRemoveDish.id,
          company: this.COMPANY.id,
          day: this.dialogRemoveDish.day,
          data: {
            item_id: this.dialogRemoveDish.item_id,
            order_id: this.dialogRemoveDish.order_id
          }
        });
        this.employeesBodyKey *= -1;
        document.querySelector("body").style.overflow = null;
      }
    },
    counterRefresh(event) {
      if (this.width < 1200) {
        if (!event.target.classList.contains("active")) {
          let btns = document.querySelectorAll(".dish-btn.checked.active");
          Array.from(btns).forEach(item => {
            item.classList.remove("active");
          });
          this.clicks = 1;
          event.target.classList.add("active");
        } else {
          this.clicks = 2;
        }
      }
    },
    async dishDayRemoveDialog(employee, day) {
      this.dialogDishDeleteConfirm = false;
      if (this.width > 1199) {
        if (!localStorage.getItem("dontAskDay")) {
          document.querySelector("body").style.overflow = "hidden";
          this.dialogDishDayDeleteConfirm = true;
        }
        this.dialogRemoveDishDay.id = employee.id;
        this.dialogRemoveDishDay.name = employee.name;
        this.dialogRemoveDishDay.last_name = employee.last_name;
        this.dialogRemoveDishDay.day = day.name;
        this.dialogRemoveDishDay.order_id = day.items[0].id;
        if (localStorage.getItem("dontAskDay")) {
          await this.DeleteDayDishes({
            day: this.dialogRemoveDishDay.day,
            user: this.dialogRemoveDishDay.id,
            company: this.COMPANY.id,
            order_id: this.dialogRemoveDishDay.order_id
          });
          this.employeesBodyKey *= -1;
          document.querySelector("body").style.overflow = null;
        }
      } else {
        if (this.clicks == 2) {
          if (!localStorage.getItem("dontAskDay")) {
            document.querySelector("body").style.overflow = "hidden";
            this.dialogDishDayDeleteConfirm = true;
          }
          this.dialogRemoveDishDay.id = employee.id;
          this.dialogRemoveDishDay.name = employee.name;
          this.dialogRemoveDishDay.last_name = employee.last_name;
          this.dialogRemoveDishDay.day = day.name;
          this.dialogRemoveDishDay.order_id = day.items[0].id;
          this.clicks = 0;
          if (localStorage.getItem("dontAskDay")) {
            await this.DeleteDayDishes({
              day: this.dialogRemoveDishDay.day,
              user: this.dialogRemoveDishDay.id,
              company: this.COMPANY.id,
              order_id: this.dialogRemoveDishDay.order_id
            });
            this.employeesBodyKey *= -1;
            document.querySelector("body").style.overflow = null;
          }
        }
      }
    },
    async dishRemove() {
      if (this.dontAskFlag) localStorage.setItem("dontAsk", true);
      this.dialogDishDeleteConfirm = false;
      await this.DeleteDish({
        user: this.dialogRemoveDish.id,
        company: this.COMPANY.id,
        day: this.dialogRemoveDish.day,
        data: {
          item_id: this.dialogRemoveDish.item_id,
          order_id: this.dialogRemoveDish.order_id
        }
      });
      this.employeesBodyKey *= -1;
      document.querySelector("body").style.overflow = null;
    },
    async dishDayRemove() {
      if (this.dontAskDayFlag) localStorage.setItem("dontAskDay", true);
      this.dialogDishDayDeleteConfirm = false;
      await this.DeleteDayDishes({
        day: this.dialogRemoveDishDay.day,
        user: this.dialogRemoveDishDay.id,
        company: this.COMPANY.id,
        order_id: this.dialogRemoveDishDay.order_id
      });
      this.employeesBodyKey *= -1;
      document.querySelector("body").style.overflow = null;
    },
    cancelDishRemoveDialog() {
      this.dialogDishDeleteConfirm = false;
    },
    cancelDishDayRemoveDialog() {
      this.dialogDishDayDeleteConfirm = false;
    },
    employeeRemove() {
      this.dialogEditEmployee.show = false;
      this.dialogEmployeeDeleteConfirm = true;
    },
    addEmployee() {
      document.querySelector("body").style.overflow = "hidden";
      this.cancelEditMe();
      this.dialogAddEmployee = true;
    },
    editEmployee(employee) {
      if (employee?.is_guest != 1) {
        document.querySelector("body").style.overflow = "hidden";
        this.guestId = employee.is_guest;
        this.selectUpdated = -this.selectUpdated;
        this.selectUpdated1 = -this.selectUpdated1;
        this.selectUpdated2 = -this.selectUpdated2;
        this.selectUpdated3 = -this.selectUpdated3;
        this.dialogEditEmployee.id = employee.id;
        this.dialogEditEmployee.show = true;
        this.dialogEditEmployee.name = employee.name ? employee.name : "";
        this.dialogEditEmployee.last_name = employee.last_name ? employee.last_name : "";
        this.dialogEditEmployee.email = employee.email;
        this.dialogEditEmployee.phone = employee.phone ? employee.phone.replace("380", "") : "";
        this.dialogEditEmployee.role_id = null;
        this.dialogEditEmployee.role = employee.role.label;
        this.dialogEditEmployee.role_default = employee.role.id.toString();
        this.dialogEditEmployee.limit_id = null;
        this.dialogEditEmployee.limit = employee?.limit?.sum.toString() ?? "0";
        this.dialogEditEmployee.min_order = employee?.min_order ?? null;

        employee?.limit?.id ? (this.dialogEditEmployee.limit_default = this.activeLimit(employee.limit.id).toString()) : (this.dialogEditEmployee.limit_default = "0");

        if (employee.birthday) {
          let bday = this.bDayFormat(employee.birthday);
          this.dialogEditEmployee.birthday = `${bday.dd} / ${bday.mm} / ${bday.yyyy}`;
        }
      }
    },
    bDayFormat(date) {
      let x = date.split("-");
      let newDate = {
        yyyy: x[0],
        mm: x[1],
        dd: x[2]
      };
      return newDate;
    },
    activeLimit(id) {
      let limit = this.limitList.find(item => item.id == id);
      return limit.number + 1;
    },
    submitEditEmployee() {
      let temp = {};
      if (this.dialogEditEmployee.name) temp.name = this.dialogEditEmployee.name;
      if (this.dialogEditEmployee.last_name) temp.last_name = this.dialogEditEmployee.last_name;
      if (this.dialogEditEmployee.limit_id !== null) temp.limit_id = this.dialogEditEmployee.limit_id;
      if (!this.guestId) {
        if (this.dialogEditEmployee.email && this.validEmail) temp.email = this.dialogEditEmployee.email;
        if (this.dialogEditEmployee.phone != "" && this.validPhone) {
          temp.phone = this.dialogEditEmployee.phone
            .split("")
            .filter(e => +e || e === "0")
            .join("");
          if (temp.phone.length < 10) temp.phone = "380" + temp.phone;
        }
        if (this.dialogEditEmployee.role_id !== null) temp.role = this.dialogEditEmployee.role_id;
        if (this.dialogEditEmployee.birthday && this.birthday.valid) temp.birthday = `${this.birthday.yyyy}-${this.birthday.mm}-${this.birthday.dd}`;
      }

      if (!this.dialogEditEmployee.min_order || this.dialogEditEmployee.min_order > 0) {
        temp.min_order = !this.dialogEditEmployee.min_order ? null : this.dialogEditEmployee.min_order;
      }

      if (Object.keys(temp).length > 0) {
        this.EditEmployee({
          user: this.dialogEditEmployee.id,
          company: this.COMPANY.id,
          temp
        });
        this.cancelEditMe();
        this.clearSearchFunc();
        document.querySelector("body").style.overflow = null;
      }
    },
    submitAddEmployee() {
      let temp = {};
      this.dialogEditEmployee.name ? (temp.name = this.dialogEditEmployee.name) : (temp.name = "");
      this.dialogEditEmployee.last_name ? (temp.last_name = this.dialogEditEmployee.last_name) : (temp.last_name = "");
      if (this.dialogEditEmployee.email && this.validEmail) temp.email = this.dialogEditEmployee.email;
      if (this.dialogEditEmployee.phone) {
        if (this.validPhone) {
          temp.phone = this.dialogEditEmployee.phone
            .split("")
            .filter(e => +e || e === "0")
            .join("");
        }
      }
      if (temp.phone) if (temp.phone.length < 10) temp.phone = "380" + temp.phone;
      if (this.dialogEditEmployee.role_id !== null) temp.role = this.dialogEditEmployee.role_id;
      if (this.dialogEditEmployee.limit_id !== null) temp.limit_id = this.dialogEditEmployee.limit_id;
      if (this.dialogEditEmployee.birthday && this.birthday.valid) temp.birthday = `${this.birthday.yyyy}-${this.birthday.mm}-${this.birthday.dd}`;

      if (this.dialogEditEmployee.min_order > 0) {
        temp.min_order = this.dialogEditEmployee.min_order;
      }

      if (Object.keys(temp).length > 0) {
        this.AddEmployee({
          company: this.COMPANY.id,
          temp
        });
        this.cancelAddEmployeeDialog();
        this.clearSearchFunc();
        document.querySelector("body").style.overflow = null;
      }
    },
    submitRemoveEmployee() {
      this.DeleteEmployee({
        name: this.dialogEditEmployee.name,
        last_name: this.dialogEditEmployee.last_name,
        user: this.dialogEditEmployee.id,
        company: this.COMPANY.id
      });
      document.querySelector("body").style.overflow = null;
      this.dialogEmployeeDeleteConfirm = false;
      this.clearSearchFunc();
    },
    cancelAddEmployeeDialog() {
      this.dialogAddEmployee = false;
      this.cancelEditMe();
    },
    cancelEditEmployeeDialog() {
      this.dialogEditEmployee.show = false;
      this.cancelEditMe();
    },
    cancelRemoveEmployeeDialog() {
      this.dialogEmployeeDeleteConfirm = false;
      this.cancelEditMe();
    },
    cancelEditMe() {
      this.selectUpdated = -this.selectUpdated;
      this.selectUpdated1 = -this.selectUpdated1;
      this.selectUpdated2 = -this.selectUpdated2;
      this.selectUpdated3 = -this.selectUpdated3;

      this.dialogEditEmployee.show = false;
      this.dialogEditEmployee.name = "";
      this.dialogEditEmployee.last_name = "";
      this.dialogEditEmployee.email = "";
      this.dialogEditEmployee.phone = "";
      this.dialogEditEmployee.role_id = null;
      this.dialogEditEmployee.role = null;
      this.dialogEditEmployee.role_default = null;
      this.dialogEditEmployee.limit_id = null;
      this.dialogEditEmployee.limit = null;
      this.dialogEditEmployee.limit_default = null;
      this.dialogEditEmployee.birthday = "";
      this.dialogEditEmployee.min_order = null;
    },
    selectRole(item) {
      if (typeof item == "object") this.dialogEditEmployee.role_id = item.id;
    },
    selectLimit(item) {
      if (typeof item == "object") this.dialogEditEmployee.limit_id = item.id;
    },
    onRolePressed(data) {
      this.user.role = data;
    },
    onLimitPressed(data) {
      this.user.limit = data;
    },
    offsetTop() {
      this.topOffset = document.querySelector(".employees-list").offsetTop;
    },
    scrollBtnShow() {
      this.pageYOffset = window.pageYOffset;
    },
    dontAskDayTimer() {
      let target = new Date();
      target.setDate(target.getDate() + 1);
      target.setHours(23, 59, 59);

      setInterval(() => {
        let curr = new Date().getTime();
        let distance = target.getTime() - curr;
        if (distance <= 0) {
          localStorage.removeItem("dontAskDay");
          localStorage.removeItem("dontAsk");
          this.dontAskDayFlag = false;
          this.dontAskFlag = false;
        }
      }, 1000);
    },
    stickyMenu() {
      let offsetTop = document.querySelector(".employees-list-header").getBoundingClientRect().top;
      offsetTop < 1 ? (this.stickyFlag = true) : (this.stickyFlag = false);
    }
  },
  watch: {
    GET_EMPLOYEES() {
      this.dataEmployee = this.GET_EMPLOYEES;
      this.dateStart = this.GET_EMPLOYEES[0].days[0].period;
      this.dateEnd = this.GET_EMPLOYEES[0].days[4].period;
    }
  },
  async mounted() {
    if (!this.VALIDATION_TRANSLATE) {
      this.ValidationTranslate();
    }
    if (!this.EMPLOYEES_TRANSLATE) {
      this.EmployeesTranslate();
    }
    if (!this.COMPANY) {
      this.UpdateCompany();
    }
    this.EmployeesList();
    if (!this.GET_ROLES) {
      this.Roles();
    }
    if (!this.ORDERS) {
      await this.GetOrders();
    }
    this.stickyMenu();

    if (!localStorage.getItem("step-employee")) {
      document.querySelector(".dark-bg-component").style.display = "block";
      localStorage.setItem("step-employee", false);
      this.infoStep++;
    }

    if (navigator.userAgent.indexOf("Mac") > 0) document.querySelector("body").classList.add("mac-os");

    this.offsetTop();
    this.scrollBtnShow();
    window.addEventListener("resize", this.offsetTop);
    window.addEventListener("scroll", this.scrollBtnShow);
    window.addEventListener("scroll", this.stickyMenu);

    Inputmask({
      mask: "+38 (099) 999 99 99",
      placeholder: ""
    }).mask(".telephone input");

    Inputmask({
      mask: "99 / 99 / 9999",
      placeholder: "",
      jitMasking: true
    }).mask(".birthday input");

    this.dontAskDayTimer();

    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.offsetTop);
    window.removeEventListener("resize", this.updateWidth);
    window.removeEventListener("scroll", this.scrollBtnShow);
    window.removeEventListener("scroll", this.stickyMenu);
  }
};
</script>

<style lang="scss">
.employees-page {
  color: var(--dark-100);
  position: relative;
  background: var(--bg-main);
  min-height: 1164px;

  .gray {
    color: var(--dark-40);
  }

  & > .main {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 200px;

    @media (max-width: 991px) {
      overflow-x: hidden;
    }
  }

  h2.r-title-1 {
    padding: 0 20px;
    margin-top: 84px;
    flex-shrink: 0;

    &:not(:first-child) {
      margin-top: 83px;
    }

    @media (max-width: 991px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 991px) {
    .employee-add-wrapper .info-meassage {
      right: 0 !important;

      .info-arrow {
        right: 32% !important;
      }
    }

    .offer-btn + .info-meassage {
      right: auto !important;

      .info-arrow {
        right: 71% !important;
      }
    }
  }

  .search-dish {
    width: 230px;

    &:focus-within {
      width: calc(300px - 21px);
    }

    .label-search {
      padding-right: 25px;
    }

    @media (max-width: 991px) {
      width: 48px;
      max-width: calc(100% - 90px);
      flex-shrink: 0;

      input::placeholder {
        color: transparent;
      }

      svg {
        margin: 0;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        position: relative;
      }
    }
  }

  .dialog-setting {
    .grey-text {
      color: var(--dark-40);
    }
    span.blue {
      color: var(--blue-main);
    }
    span.red {
      color: var(--error);
    }
    .dialog-header h2 {
      padding-right: 10px;

      .grey-text {
        font-family: "Aeonik Pro Regular", sans-serif;
      }
    }
    .surcharge-text {
      max-width: 540px;
      padding-bottom: 20px;
    }
  }

  .confirm-box {
    margin-left: auto;
    display: flex;
    align-items: center;
    span {
      cursor: default;
      margin-bottom: -3px;
    }
  }

  .check-confirm {
    position: relative;
    top: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid var(--blue-main);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    overflow: hidden;
    svg {
      opacity: 0;
      width: 22px;
      height: 22px;
      color: var(--blue-main);
      transition: 0.2s;
      visibility: hidden;
    }
    & input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"]:checked ~ svg {
      visibility: visible;
      background: var(--blue-main);
      opacity: 1;

      path {
        stroke: #fff;
      }
    }
  }

  .remove-btn-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;

    @media (max-width: 375px) {
      margin: 40px auto 0;
    }
  }

  .remove-btn {
    padding: 0;
    color: var(--error);
    font-size: 14px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .dialog-edit-company {
    span.message {
      color: var(--dark-40);
      font-family: "Aeonik Pro", sans-serif;
    }
    .inputs-box {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .main {
        margin-bottom: 33px;
        max-width: 45%;

        @media (max-width: 700px) {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  .custom-select {
    max-width: 325px;
    width: 100%;

    @media (max-width: 700px) {
      max-width: 100%;
    }
  }

  .employees {
    &-header {
      margin-bottom: 40px;
      padding-right: 20px;
      width: 100%;
      display: flex;

      @media (min-width: 992px) {
        justify-content: space-between;
        align-items: flex-end;
      }

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }

      &-buttons {
        width: 100%;
        display: flex;

        @media (min-width: 992px) {
          margin-bottom: 3px;
          justify-content: flex-end;
        }

        @media (max-width: 991px) {
          padding-left: 20px;

          .guest-info {
            display: none !important;
          }
        }

        & > *:not(:first-child) {
          margin-left: 20px;

          @media (max-width: 991px) {
            margin-left: 12px;
          }
        }
      }
    }

    &-list {
      width: 100%;

      &-header {
        padding: 12px 40px;
        display: flex;
        position: sticky;
        top: 0;
        z-index: 2;

        &.sticky {
          backdrop-filter: saturate(180%) blur(20px);
          background-color: rgba(245, 248, 250, 0.72);
        }

        @media (max-width: 991px) {
          display: none;
        }
      }

      &-title {
        flex-shrink: 0;
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 2;
        text-transform: uppercase;

        &.title-name {
          width: 24.6%;
        }

        &.title-phone {
          width: 17.8%;
        }

        &.title-limit {
          width: 19.1%;
        }

        &.title-status {
          width: 38.5%;
        }

        & > span {
          &.hovered {
            cursor: pointer;
            transition: 0.2s ease-out;

            &:hover {
              color: var(--blue-btn-hover);
            }
          }

          svg {
            position: relative;
            bottom: 1px;
            transition: 0.5s ease;

            &.rotated {
              transform: rotate(-180deg);
            }
          }
        }
      }

      &-body {
        padding: 0 20px;
      }

      &-item {
        margin-bottom: 4px;
        padding: 13px 20px 11px;
        display: flex;
        align-items: center;
        font-size: 12px;
        background: var(--white-100);
        border-radius: 10px;

        @media (max-width: 991px) {
          flex-wrap: wrap;
        }

        & > div {
          display: flex;
          align-items: center;
          flex-shrink: 0;

          @media (min-width: 992px) {
            &.employee-name {
              width: 24.6%;
            }

            &.employee-phone {
              width: 17.8%;
            }

            &.employee-limit {
              width: 19%;
            }

            &.employee-offers {
              width: 24%;
            }

            &.offer-btn-wrapper {
              padding-right: 13px;
              width: 14.6%;
            }
          }

          @media (min-width: 992px) {
            &:last-child {
              justify-content: flex-end;
            }
          }
        }

        &.empty-text {
          padding: 24px 20px;
        }
      }
    }
  }

  .date-interval {
    color: var(--dark-40);
  }

  .employee {
    &-icon {
      margin-right: 6px;
      position: relative;
      bottom: 1px;
      z-index: 1;
      flex-shrink: 0;
    }
    &-name {
      padding-right: 10px;
      transition: 0.2s ease-out;

      cursor: pointer;
      &:hover {
        color: var(--blue-btn-hover);
      }
    }
    &-add-wrapper {
      position: relative;
      flex-shrink: 0;
    }
  }

  .rank {
    display: block;
    margin-left: 5px;
    color: var(--dark-40);

    @media (max-width: 991px) {
      display: none;
    }
  }

  .btn-to-top {
    z-index: 2;
    transition: 0.3s;
    position: fixed;
    bottom: 66px;
    right: 20px;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white-100);
    border-radius: 10px;
    box-shadow: var(--box-shadow-second);
    cursor: pointer;
    &:active {
      transform: scale(0.9);
      box-shadow: var(--box-shadow-second);
    }
  }

  .offer-btn {
    padding: 0;
    display: flex;
    align-items: center;
    color: var(--dark-40);
    font-size: 12px;
    letter-spacing: 0.4px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.2s ease-out;

    svg {
      position: relative;
      right: 4px;
      bottom: 1px;
    }

    &:hover {
      color: var(--blue-btn-hover);
    }

    &-wrapper {
      position: relative;
      flex-shrink: 0;
    }
  }
  .step-interactive-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    span {
      height: 4px;
      width: 24px;
      border-radius: 10px;
      background: var(--white-40);
      margin-right: 8px;
      margin-bottom: 6px;
      &.active {
        background: var(--white-100);
      }
    }
  }
  .step-btn {
    background: var(--white-100);
    border-radius: 8px;
    height: 48px;
    cursor: pointer;
    font-family: "Aeonik Pro", sans-serif;
    text-transform: uppercase;
    color: var(--blue-main);
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
  .dish-day {
    position: relative;

    &:after {
      content: url("data:image/svg+xml,%3Csvg width='73' height='12' viewBox='0 0 73 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.7 11.1C36.9889 11.6333 36.0111 11.6333 35.3 11.1L20.5 0H52.5L37.7 11.1Z' fill='white'/%3E%3C/svg%3E%0A");
      display: block;
      position: absolute;
      top: calc(100% - 3px);
      left: 50%;
      transform: translateX(-50%);
    }

    &-wrapper {
      position: absolute;
      display: none;
      bottom: calc(100% + 10px);
      right: 50%;
      transform: translateX(50%);
      box-shadow: var(--box-shadow-second);
      z-index: 2;

      &.bottom {
        bottom: auto;
        top: calc(100% + 10px);

        .dish-day::after {
          top: auto;
          bottom: calc(100% - 3px);
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }
  }

  .guest {
    position: relative;
    cursor: default;

    &:hover {
      color: initial;
    }

    &-info {
      display: none;
      position: absolute;
      bottom: calc(100% + 12px);
      left: -28px;
      z-index: 1;

      &-inner {
        padding: 20px;
        position: relative;
        width: 286px;
        color: var(--bg-white);
        font-size: 14px;
        line-height: 22px;
        background: var(--blue-main);
        border-radius: 10px;

        // @media (max-width: 767px) {
        //   display: none;
        // }

        &:after {
          content: url("data:image/svg+xml,%3Csvg width='72' height='12' viewBox='0 0 72 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.2 11.1C36.4889 11.6333 35.5111 11.6333 34.8 11.1L20 0H52L37.2 11.1Z' fill='%233CAFE9'/%3E%3C/svg%3E%0A");
          display: block;
          position: absolute;
          top: calc(100% - 4px);
          left: 0;
        }
      }
    }

    .employee-icon:hover + .guest-info {
      display: block;
    }
  }

  .employees-list-item > .employee-phone a {
    color: var(--dark-100);
    text-decoration: none;
    transition: 0.2s ease-out;
    &:hover {
      color: var(--blue-btn-hover);
    }
  }

  @media (max-width: 991px) {
    .employees-list-item > .employee-phone {
      display: none;
    }

    .employees-list-item {
      & > .employee-name {
        width: 75%;
      }

      & > .employee-limit {
        width: 25%;
      }

      & > .employee-name,
      & > .employee-limit {
        margin-bottom: 16px;
      }

      & > .employee-offers,
      & > .offer-btn-wrapper {
        width: 100%;
      }

      & > .employee-offers {
        margin-bottom: 16px;
      }

      & > .employee-limit {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .dish-remove-dialog {
    @media (max-width: 767px) {
      .dialog-btns {
        flex-wrap: wrap;

        .confirm-box {
          margin: 0 0 36px;
          width: 100%;
          order: 1;
        }

        .mr {
          order: 2;
        }

        & > button {
          order: 3;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .birthday .info-meassage {
      left: auto !important;
      right: -20px;

      .info-arrow {
        left: auto;
        right: 22px;
      }
    }
  }

  .btn-info:hover {
    & > div {
      display: block;
      text-transform: none;

      @media (max-width: 991px) {
        left: 0;
      }

      .guest-info-inner:after {
        left: 15px;

        @media (max-width: 991px) {
          left: -13px;
        }
      }
    }
  }
}

.dish-check-container > div {
  position: relative;
}

.employees-page .dish-check-container {
  display: flex;
  position: relative;

  @media (max-width: 991px) {
    margin-left: -2px;
  }

  & > div {
    width: 100%;
    margin: 0 2px;

    &:hover {
      .dish-day-wrapper {
        display: block;
      }

      .checked.dish-btn {
        background: var(--error);

        .status-1 {
          display: none;
        }

        .status-2 {
          display: block;
        }
      }
    }
  }
  .dish-btn {
    user-select: none;
    transition: 0.2s ease-out;
    color: var(--dark-100);
    width: 54px;
    height: 48px;
    background: var(--bg-main);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    & > * {
      pointer-events: none;
    }

    &.checked {
      cursor: pointer;
    }

    @media (max-width: 1439px) and (min-width: 1200px) {
      width: 45px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      width: 40px;
      height: 40px;
    }

    @media (max-width: 991px) {
      width: 53px;
    }

    @media (max-width: 359px) {
      width: 45px;
    }

    svg {
      display: none;
      height: 16px;
      width: 13px;
      margin-top: -4px;
      margin-right: 2px;

      @media (max-width: 991px) {
        margin-top: -3px;
      }
    }
  }
  .checked.dish-btn {
    color: var(--white-100);
    background: var(--blue-main);
    svg:not(.status-2) {
      display: flex;
    }
  }
  .dish-btns {
    position: relative;
  }

  .info-meassage {
    transform: translateX(-50%);
  }

  @media (max-width: 767px) {
    & > div:nth-child(1) .dish-day-wrapper {
      right: auto;
      left: -65%;
      transform: none;

      .dish-day:after {
        left: 18%;
      }
    }

    & > div:nth-child(2) .dish-day-wrapper {
      right: auto;
      left: -170%;
      transform: none;

      .dish-day:after {
        left: 34%;
      }
    }

    & > div:nth-child(4) .dish-day-wrapper {
      right: -173%;
      transform: none;

      .dish-day:after {
        left: 67%;
      }
    }

    & > div:nth-child(5) .dish-day-wrapper {
      right: -69%;
      transform: none;

      .dish-day:after {
        left: 81%;
      }
    }
  }
}
.mac-os .employees-page .dish-check-container .dish-btn svg {
  margin-top: -1px;
}
</style>
